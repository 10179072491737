import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import { SwiperGallery } from "../components/swiperGallery.tsx";
import ImageFadeIn from "react-image-fade-in";

const Publication = ({ data }) => {
  const publication = data.datoCmsSinglePublication;
  const { publications } = data;
  const [relatedPublications, setRelatedPublications] = useState([]);

  useEffect(() => {
    if (publication.similarPublications === 3) {
      setRelatedPublications(publication.similarPublications);
    } else {
      const relatedPublicationsObj = [];
      data.allDatoCmsSinglePublication.edges.forEach(({ node: pub }) => {
        if (pub.id === publication.id) {
          return;
        }

        if (relatedPublicationsObj.length === 3) {
          return;
        }

        if (pub.category.title === publication.category.title) {
          relatedPublicationsObj.push(pub);
        }
      });

      if (relatedPublications.length === 0 && relatedPublicationsObj.length > 0) {
        setRelatedPublications(relatedPublicationsObj);
      }
    }
  }, []);

  return (
    <Layout bgcolor={publications.backgroundColor} wrapperClass="single-publication" accentColor={publications.accentColor}>
      <HelmetDatoCms seo={publication.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/publications" className="sidebar-text">Publications</Link>
        </div>
        <div className="col-sm-8 col-xs-6 col">
          <h1
            className="gothic--xlarge large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: publication.formattedTitle,
            }}
          />
        </div>
      </div>
      <div className="container row reverse single-article-content">
        <div className="publication-info col-sm-4 offset-sm-1 col-xs-6 col">
          <h4 className="sans--regular">
            {publication.category.title}
            <br />
            {publication.year}
          </h4>
          <div
            className="content main-intro publication-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: publication.details,
            }}
          />
          <div className="divider-intro">
            <DividerBlock accentColor={publications.accentColor} />
          </div>
        </div>
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
					<div className="feature-image">
						{publication.featuredImage && (
							<ImageFadeIn
								src={publication.featuredImage.url}
								alt={publication.featuredImage.alt}
								className="publications-featured-image"
							/>
						)}
					</div>
          <div className="main-body">
            <div
              className="content text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: publication.description,
              }}
            />
						{publication.downloadFile && (
							<div className="download-file">
							<Link to={publication.downloadFile.url} target="_blank" rel="noreferrer" download={publication.downloadText}>
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none">
									<circle cx="20" cy="20" r="19" stroke="black" strokeWidth="2" />
									<path d="M11.5791 23.1578L20.0002 31.5789L28.4212 23.1578" fill="none" stroke="black" strokeWidth="2" />
									<path d="M20 7.36841L20 31.5789" fill="none" stroke="black" strokeWidth="2" />
								</svg>
								<div className="text-wrapper">
									<p style={{ marginBottom: '0', textDecoration: 'underline' }}>{publication.downloadText}</p>
								</div>
							</Link>
						</div>
						)}
          </div>
        </div>
      </div>
			{publication.imageGallery.length > 0 && (
        <div className="swiper-gallery">
          <SwiperGallery slides={publication.imageGallery} />
        </div>
      )}
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={publications.accentColor} />
        </div>
      </div>
      {relatedPublications.length > 0 && (
        <div className="container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Similar publications</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {relatedPublications.map((pub) => (
              <Link
                to={`/publications/${pub.slug}`}
                className="tile col"
                key={pub.id}
              >
                {pub.featuredImage && (
									<ImageFadeIn
										src={pub.featuredImage.url}
										alt={pub.featuredImage.alt}
										className="tile-image"
									/>
                )}
                <div className="tile-text-wrap">
                  <h4
                    className="sans--regular publication-title"
                      // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: pub.formattedTitle,
                    }}
                  />
                  <span className="publication-year">{pub.year}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

Publication.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Publication;

export const query = graphql`
  query SinglePublicationQuery($slug: String!) {
    publications: datoCmsPublication {
      backgroundColor
      accentColor {
        hex
      }
    }
    datoCmsSinglePublication(slug: { eq: $slug }) {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      imageGallery {
        url(
					imgixParams: { h: "1000", fit: "clip" }
				)
      }
			featuredImage {
				url(imgixParams: { w: "1000", ar: "1:1", fit: "crop", crop: "focalpoint" })
				alt
			}
      formattedTitle
      description
      year
      details
      category {
        title
      }
			downloadFile {
				url
			}
			downloadText
      similarPublications {
        id
        slug
        year
        formattedTitle
        featuredImage {
          alt
					url(imgixParams: { w: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
        }
      }
      nonStudioArtists {
        ... on DatoCmsNonStudioArtist {
          id
          artistName
        }
      }
      artists {
        ... on DatoCmsArtist {
          name
        }
      }
    }
    allDatoCmsSinglePublication {
      edges {
        node {
          id
          slug
          year
          formattedTitle
          featuredImage {
            alt
						url(imgixParams: { w: "450", ar: "1:1", fit: "crop", crop: "focalpoint" })
          }
          category {
            title
          }
        }
      }
    }
  }
`;
